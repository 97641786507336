<template>
  <div class="search-bar-mobile nav-main">
    <div class="nav-left">
      <div class="has-text-centered nav-item nav-link" style="width: 32px;"
        @click.stop.prevent="handleVisibility">
        <i class="el-icon-search is-size-5 has-text-dark" />
      </div>
      <div class="nav-item nav-link"
        @click.stop.prevent="handleVisibility('search')">
        <div>
        <strong class="has-text-primary has-text-ellipsis"
          :style="{ width: maxAvailableWidth + 'px', display: 'block' }">{{ locationQuery }}</strong>
        <div class="list-label is-size-7 has-text-ellipsis has-text-grey" :style="{ width: maxAvailableWidth + 'px', display: 'block' }">
          <span v-html="dateRangeStr"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span v-html="guests"></span>
        </div>
        </div>
      </div>
    </div>
    <div class="nav-right">
      <a class="nav-item nav-link"
        @click.stop.prevent="handleVisibility('filter')">
        <IconBase
          class="icon-svg"
          iconName="icon-filter">
          <IconFilter />
        </IconBase>
        <div v-if="hasFilter" class="highlight-dot attached" />
      </a>
    </div>
    <transition name="view-slide-left">
      <div
        v-if="searchFormVisibility"
        class="search-bar-mobile--pop"
        @click.stop="handleStop">
        <!-- TODO: use PpOverlay for this -->
        <PpCard class="search-bar-mobile--pop-content has-background-white">
          <a>
          <i class="is-pulled-right is-size-3 el-icon-close"
            @click.stop.capture="handleClose"/>
          </a>
          <h3 class="subtitle is-4 is-inline-block">Where?</h3>
          <div class="wrapper">
            <SearchBarMany
              :queryParams="queryParams"
              @search="searched"/>
          </div>
        </PpCard>
        <PpOverlay
          v-if="!isMobile"
          :zIndex="1999"/>
      </div>
      <div
        v-if="filterFormVisibility"
        class="search-bar-mobile--pop"
        @click.stop="handleStop">
        <!-- TODO: use PpOverlay for this -->
        <PpCard class="search-bar-mobile--pop-content has-background-white">
          <a>
          <i class="is-pulled-right is-size-3 el-icon-close"
            @click.stop.capture="handleClose"/>
          </a>
          <h3 class="subtitle is-4 is-inline-block">Filter?</h3>
          <SearchManyFilters
            :layoutMode="$mq.all"
            @search="handleFilterChanged"/>
          <div class="list search-bar-mobile--pop-foot"
            slot="foot">
            <div class="list-action">
              <el-button
                @click.stop="handleClose">Close</el-button>
            </div>
            <div class="list-content">
              <el-button type="primary"
                style="width: 100%;"
                @click.stop="searched(localFilterForm)"
              >Filter</el-button>
            </div>
          </div>
        </PpCard>
        <PpOverlay
          v-if="!isMobile"
          :zIndex="1999"/>
      </div>
    </transition>
  </div>
</template>

<script>
import IconFilter from '../components/icons/IconFilter'

const dateFormat = {
  timeZone: 'UTC',
  month: 'short',
  day: '2-digit'
}
export default {
  name: 'SearchBarManyMobile',
  components: {
    'IconFilter': IconFilter,
    'SearchBarMany': () => import(/* webpackChunkName: "mobiledefer" */ '../components/SearchBarMany'),
    'SearchManyFilters': () => import(/* webpackChunkName: "mobiledefer" */ '../components/SearchManyFilters')
  },
  props: {
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    },
    queryParams: Object
  },
  data () {
    return {
      searchFormVisibility: false,
      filterFormVisibility: false,
      localFilterForm: {

      },
      maxAvailableWidth: undefined
    }
  },
  computed: {
    isMobile () {
      return this.layoutMode.includes('mobile')
    },
    hasFilter () {
      return this.queryParams.propertyName || this.queryParams.priceRange || this.queryParams.tyRange || this.queryParams.starRange
    },
    locationQuery () {
      return this.queryParams.locationQuery
    },
    dateRangeStr () {
      if (!this.queryParams.checkInDate) {
        return ''
      }
      const checkIn = this.$root.$options.filters.date(this.queryParams.checkInDate, dateFormat)
      const checkOut = this.$root.$options.filters.date(this.queryParams.checkOutDate, dateFormat)
      return `<strong>${checkIn}</strong>  –  <strong>${checkOut}</strong>`
    },
    roomsLabel () {
      return this.queryParams.roomCount > 1 ? `<strong>${this.queryParams.roomCount}</strong> rooms` : '<strong>1</strong> room'
    },
    adultLabel () {
      return this.queryParams.adultCount > 1 ? `<strong>${this.queryParams.adultCount}</strong> adults` : '<strong>1</strong> adult'
    },
    childrenLabel () {
      return this.childrenParser.length > 0 ? `, <strong>${this.childrenParser.length}</strong> children` : ', 0 children'
    },
    childrenParser () {
      return this.queryParams.children ? this.queryParams.children.split(',') : []
    },
    guests () {
      return `${this.adultLabel}, ${this.roomsLabel}${this.childrenLabel}`
    }
  },
  mounted () {
    // TODO: this is not resposive. need to listen to browser resize event
    const k = document.querySelector('.search-bar-mobile')
    if (k != null) {
      this.maxAvailableWidth = k.offsetWidth - 110
    }
  },
  activated () {
    window.addEventListener('popstate', this.handlePopState)
  },
  deactivated () {
    window.removeEventListener('popstate', this.handlePopState)
  },
  methods: {
    handleStop () {
      // Keep this. to prevent click event bubble up
    },
    searched (val) {
      this.$emit('search', val)
      this.closeModal()
    },
    handleClose () {
      history.back()
    },
    handlePopState (e) {
      // const { state } = e
      // reset
      /**
       * TODO: got some small issue that trigger search again when going back with history
       */
      this.$lv2Store.setQueryParams({ children: undefined, ...this.$route.query, ...this.$route.params })
      this.closeModal()
    },
    handleFilterChanged (val) {
      this.localFilterForm = { ...this.localFilterForm, ...val }
      const k = Object.keys(val)
      if (k.includes('propertyName')) {
        this.searched(this.localFilterForm)
      }
    },
    closeModal () {
      const html = document.querySelector('html')
      html.classList.remove('is-clipped')
      this.searchFormVisibility = false
      this.filterFormVisibility = false
      this.localFilterForm = {}
    },
    handleVisibility (type) {
      let historyMethod
      const html = document.querySelector('html')
      html.classList.add('is-clipped')
      switch (type) {
        case 'filter':
          this.filterFormVisibility = !this.filterFormVisibility
          historyMethod = history.state.filterFormVisibility == null ? 'pushState' : 'replaceState'
          history[historyMethod]({ filterFormVisibility: this.filterFormVisibility }, 'Open search form', '')
          break
        default:
          this.searchFormVisibility = !this.searchFormVisibility
          historyMethod = history.state.searchFormVisibility == null ? 'pushState' : 'replaceState'
          history[historyMethod]({ searchFormVisibility: this.searchFormVisibility }, 'Open search form', '')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .icon-svg {
    width: 1.8rem;
    height: 1.8rem;
  }
  .icon-svg /deep/ #icon-filter {
    fill: $link;
    // fill: #f9980eed;
  }
  .search-bar-mobile--pop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $_jh_fullscreen-z-index;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .search-bar-mobile--pop-foot {
    padding: $bleed * 2;
  }
  .search-bar-mobile.list {
    margin: 0;
    padding: 0;
  }
  .search-bar-mobile--pop-content {
    height: 100%;
    .subtitle,
    .el-icon-close {
      padding: $bleed * 2;
    }
    padding: $bleed;
    .wrapper,
    /deep/ .shadow-box {
      padding: 0 $bleed * 2;
      border-radius: 0;
      box-shadow: none;
    }
    /deep/ .list.has-border-bottom {
      border-bottom: 0 !important;
    }
  }
</style>
