<template>
  <div class="packages">
    <div
      class="packages--price-wrapper">
        <div class="packages--discount"
          v-if="pckg.initialAdjustmentPct < -0.00">
          {{ displayRate | currencyObj }}
        </div>
        <div class="packages--price">{{ adjustedDisplayRate | currencyObj }}</div>
        <div class="packages--price-caption">Nightly avg.</div>
        <div
          v-if="$slots['call-to-action']"
          class="packages--call-to-action">
          <slot name="call-to-action">
          </slot>
        </div>
    </div>
    <h6 class="packages--description">
      {{ pckg.supplierDescription || pckg.description }}
    </h6>
    <PackagesFoodCode class="packages--foodCode" :foodCode="pckg.foodCode" :hideDescription="true"/>
    <PpGap size="small"/>
    <AdjustmentBadge
    class="packages--discount-badge"
    v-if="(pckg.initialAdjustmentPct < -0.00)"
    :adjustmentPct="pckg.initialAdjustmentPct" />
    <div class="packages--marketrates"
      v-if="marketRates[0]">
      <div class="supplier">{{ marketRates[0] && marketRates[0].supplier }}</div>
      {{ marketRates[0] && marketRates[0] | currencyObj }}
    </div>
  </div>
</template>

<script>
import PackagesFoodCode from './PackagesFoodCode'
import AdjustmentBadge from './AdjustmentBadge'

export default {
  name: 'PackagesMobile',
  components: {
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PackagesFoodCode.name]: PackagesFoodCode
  },
  props: {
    pckg: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      inNightlyAvgRate: true
    }
  },
  computed: {
    nonRefundable () {
      return this.pckg.nonRefundable == null
    },
    marketRates () {
      return this.inNightlyAvgRate ? this.pckg.unitMarketRates : this.pckg.marketRates
    },
    displayRate () {
      return this.inNightlyAvgRate ? this.pckg.unitDisplayRate : this.pckg.displayRate
    },
    adjustedDisplayRate () {
      return this.inNightlyAvgRate ? this.pckg.unitAdjustedDisplayRate : this.pckg.adjustedDisplayRate
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .packages {
    padding: $bleed * 2;
    background-color: $white;
    border-radius: $bleed /2;
    line-height: 1.125em;
    &::after {
      clear: both;
      content: " ";
      display: table;
    }
  }
  .packages--price-wrapper {
    font-size: 1em;
    color: $text;
    text-align: right;
    float: right;
    padding: 0 0 0 0.5em;
  }
  .packages--discount-badge,
  .packages--foodCode {
    font-size: 0.75em;
  }
  .packages--call-to-action {
    margin-top: 0.5em;
    // text-align: right;
    float: right;
  }
  .packages--description {
    text-transform: uppercase;
    font-size: 0.75em;
    color: $dark;
    font-weight: 600;
  }
  .packages--price-caption {
    font-size: 0.75em;
    opacity: 0.68;
  }
  .packages--price {
    font-size: 1em;
    font-weight: 600;
    color: $dark;
  }
  .packages--discount {
    text-decoration: line-through;
    font-size: 0.75em;
  }
  .packages--marketrates {
    text-align: left;
    font-size: 0.85em;
    .supplier {
      font-size: 0.6875em;
      text-transform: capitalize;
    }
  }
</style>
