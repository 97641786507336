<template>
  <div class="item list wrap is-gapless is-narrow is-topless is-bottomless">
    <div class="col-img">
      <TrustYouRanked
        class="is-size-7 ribun" layout="vertical" v-bind="ranking && ranking.badgeData" />
      <PropertyGalleryHover
        :hasGalleryHidden="true"
        :gallery="property.gallery"
        :heroImage="property.heroImage"
        @click.native.prevent.stop="handleHeroImageClicked">
        <i slot="hero" v-if="$mq.mobile" class="gallery-hover--hero-slot el-icon-zoom-in is-size-4" />
      </PropertyGalleryHover>
    </div>
    <div class="col-content list-content">
      <div class="col-cotnent-wrapper">
        <h6 class="has-text-dark prop-name has-text-weight-semibold subtitle is-6 is-marginless">
          {{ property.name }}
        </h6>
        <router-link
          v-if="$route.name === 'searchMany'"
          class="is-italic property--location"
          @click.native="lll"
          :to="{
            name: 'searchMap', query: Object.assign({}, lv2State.queryParams), params:  lv2State.queryParams, hash: '#pc' + property.propertyCode }"
          >
          ~ {{ reviews.summary && reviews.summary.location && reviews.summary.location.text }} (view map)
        </router-link>
        <p
          v-else
          class="is-italic has-text-grey property--location">~ {{ reviews.summary && reviews.summary.location && reviews.summary.location.text }}</p>
        <PpGap size="small" v-if="reviews.summary.location" />
        <div class="list is-gapless wrap is-narrow is-topless">
          <StarRatings class="prop-star is-size-7 list-content" :ratings="property.starRating" />
          <TrustYouRating
            class="list-action"
            size="xs"
            v-bind="property.trustYou" />
        </div>
          <TrustYouPopularWith
            layout="text"
            class="is-size-7 has-text-dark"
            v-bind="reviews.summary.popularWith" />
        <PpGap size="small" />
        <template v-for="it in reviews.hotelTypeList">
          <TrustYouHotelType
            class="property--trustyou-hotel-type is-size-7 has-text-grey"
            :key="it.categoryId"
            textType="shortText"
            v-bind="it"
            />
        </template>
        <!-- <div class="column is-narrow col-pckg-details">
          <div style="flex: 1;">
            <PackagesFoodCode
              class="is-size-7"
              :hideRoomOnly="true"
              :foodCode="lowestPckg.foodCode" />
          </div>
          <div class="has-text-right" v-opacity-zero="!marketRates[0]">
            <div class="is-size-7 has-text-grey">{{ marketRates[0] && marketRates[0].supplier }}</div>
            <div class="is-size-6 has-text-grey">
              {{ marketRates[0] && marketRates[0] | currencyObj({ factionDigits: 0 }) }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div
      class="col-price list-content">
      <div class="col-content-wrapper list is-gapless is-narrow is-topless is-bottomless">
        <div class="list-content">
            <PackagesFoodCode
              class="is-size-7"
              :hideRoomOnly="true"
              :hideDescription="true"
              :foodCode="lowestPckg.foodCode" />
          <PpGap size="small" />
          <AdjustmentBadge
            class="is-size-7"
            :adjustmentPct="lowestPckg.initialAdjustmentPct" />
        </div>
        <!-- <div class="column">
          <TrustYouBadgeList
            style="font-size: 0.6em;"
            :popularityThreshold="3"
            :singleMode="true"
            :limit="1"
            :badgeList="reviews.badgeList"/>
        </div> -->
        <div class="list-action is-size-6 has-text-black has-text-right">
          <div class="has-text-line-through subtitle is-7 has-text-grey is-marginless"
            v-if="lowestPckg.initialAdjustmentPct < -0.00">
            {{ displayRate | currencyObj({ factionDigits: 0 }) }}
          </div>
          <div class="is-5 is-marginless subtitle has-text-weight-semibold has-text-dark">{{ adjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}</div>
          <div class="is-size-7 has-text-grey">Nightly avg.</div>
        </div>
      </div>
    </div>
    <div class="property--market-rate list-content"
      v-if="marketRates[0]">
      <span class="is-size-7 has-text-grey">
        {{ marketRates[0] && marketRates[0].supplier }}
        {{ marketRates[0] && marketRates[0] | currencyObj({ factionDigits: 0 }) }}
      </span>
    </div>
  </div>
</template>

<script>
// import PropertyAddress from '@/components/PropertyAddress'
import StarRatings from '@/components/StarRatings'
import AdjustmentBadge from '@/components/AdjustmentBadge'
import PropertyGalleryHover from '@/components/PropertyGalleryHover'
import BadgeOutline from '@/components/BadgeOutline'
import TrustYouRating from '@/components/TrustYouRating'
import TrustYouRanked from '@/components/TrustYouRanked'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouBadgeList from '@/components/TrustYouBadgeList'
import PackagesFoodCode from './PackagesFoodCode'

export default {
  name: 'PropertyMobile',
  components: {
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PropertyGalleryHover.name]: PropertyGalleryHover,
    // [PropertyAddress.name]: PropertyAddress,
    [PackagesFoodCode.name]: PackagesFoodCode,
    [BadgeOutline.name]: BadgeOutline,
    [StarRatings.name]: StarRatings,
    [TrustYouRanked.name]: TrustYouRanked,
    [TrustYouRating.name]: TrustYouRating,
    [TrustYouPopularWith.name]: TrustYouPopularWith,
    [TrustYouHotelType.name]: TrustYouHotelType,
    [TrustYouRecommendation.name]: TrustYouRecommendation,
    [TrustYouBadgeList.name]: TrustYouBadgeList
  },
  props: {
    property: Object,
    packages: Array,
    nights: Number,
    layout: String
  },
  data () {
    return {
      inNightlyAvgRate: true
    }
  },
  computed: {
    mapLink () {
      return this.layout !== 'simple'
    },
    ranking () {
      return this.reviews.badgeList && this.reviews.badgeList.find(({ badgeType }) => badgeType === 'ranking') || {}
    },
    reviews () {
      return this.property.reviews && this.property.reviews.summary && this.property.reviews || { summary: {}, badgeList: [], hotelTypeList: [] }
    },
    lowestPckg () {
      return this.packages[0] || {}
    },
    marketRates () {
      return (this.inNightlyAvgRate ? this.lowestPckg.unitMarketRates : this.lowestPckg.marketRates) || []
    },
    displayRate () {
      return this.inNightlyAvgRate ? this.lowestPckg.unitDisplayRate : this.lowestPckg.displayRate
    },
    adjustedDisplayRate () {
      return this.inNightlyAvgRate ? this.lowestPckg.unitAdjustedDisplayRate : this.lowestPckg.adjustedDisplayRate
    }
  },
  methods: {
    handleHeroImageClicked () {
      this.$emit('heroImageClicked', { property: this.property, packages: this.packages })
    },
    lll (evt) {
      console.log(evt)
      evt.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .item {
    line-height: 1rem;
    // padding: ($bleed * 2) $bleed;
    margin: 0;
    // justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .property--trustyou-hotel-type {
  }
  .property--location {
    font-size: 0.75em;
  }
  .property--market-rate {
    border-top: 1px solid $list-border-color;
    padding: $bleed $bleed * 2;
    text-align: center;
    background-color: $white-bis;
    border-radius: 0 0 $bleed $bleed;
  }
  .col-img {
    padding: 0;
    // height: 100%;
    align-self: stretch;
  }
  .col-content {
    padding: $bleed * 2;
    .col-cotnent-wrapper {
      height: 100%;
      flex-direction: column;
    }
  }
  .col-pckg-details {
    display: flex;
    align-items: flex-end;
  }
  .col-price {
    padding: $bleed $bleed * 2 $bleed * 2 $bleed * 2;
    flex: none;
    width: 100%;
    .col-content-wrapper {
      align-items: flex-end;
    }
    // width: 100%;
    // padding-top: 0;
    // min-width: 200px;
    // align-self: center;
    // .col-cotnent-wrapper {
    //   flex-direction: row;
    // }
    // background-color: #fff;
    // padding: $bleed / 2 $bleed !important;
    // align-self: flex-end;
    // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
  }
  .ribun {
    position: absolute;
    z-index: 1;
    margin: $bleed $bleed $bleed -#{$bleed * 2};
  }
  .prop-star {
    width: 70px;
  }
    .col-label {
      // background-color: #fff;
      // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
      // padding: $bleed / 2 $bleed;
      // flex: 1;
      // margin-left: - ($bleed * 2) + 2;
      // order: -2;
      .prop-name {
        // display: inline-block;
        // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
      }
    }
    .col-public-rate {
      // background-color: #fff;
      // padding: $bleed / 2 $bleed;
      // align-self: flex-start;
      // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
    }
    .col-adjustment {
      // background-color: #fff;
      // padding: $bleed / 2 $bleed;
      // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
    }
    .item-gap {
      margin-top: $bleed * 25;
    }
  // .fixed-height {
  //   height: calc(100vh - #{$zu-header-height} - 112px - 50px);
  //   overflow-y: scroll;
  // }
  // #map-canvas {
  //   height: calc(100vh - #{$zu-header-height} - 112px);
  //   width: 100%;
  // }
</style>
